import { Route, Switch } from "react-router-dom";
import Deeplink from "./deeplink";

function App() {
  return (
    <div>
      <Switch>
        {/* <Route exact path="/google-oauth" component={GoogleOAuth} /> */}
        <Route path="/" component={Deeplink} />
      </Switch>
    </div>
  );
}

export default App;
